import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";

@Component({
  selector: 'app-service-charges',
  standalone: true,
  templateUrl: './service-charges.component.html',
  styleUrl: './service-charges.component.scss',
  imports: [HeaderComponent, FooterComponent]
})
export class ServiceChargesComponent {

}
